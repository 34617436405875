import TwoInOneHero from 'public/landing-page_2-em-1_hero.png'
import AmbevHero from 'public/landing-page_ambev_hero.png'
import AmbevLogo from 'public/landing-page_ambev_logo.png'
import BeesHero from 'public/landing-page_bees_hero.png'
import BeesLogo from 'public/landing-page_bees_logo.png'
import GenericHero from 'public/landing-page_generico_hero.png'
import GetinLogo from 'public/landing-page_getin_logo.png'
import GympassHero from 'public/landing-page_gympass_hero.png'
import GympassHeroMobile from 'public/landing-page_gympass_hero_mobile.png'
import GympassLogo from 'public/landing-page_gympass_logo.png'
import KibonHero from 'public/landing-page_kibon_hero.png'
import KibonHeroMobile from 'public/landing-page_kibon_hero_mobile.png'
import KibonLogo from 'public/landing-page_kibon_logo.png'
import SaveeHero from 'public/landing-page_savee_hero.png'
import SaveeLogo from 'public/landing-page_savee_logo.png'

const Images: Record<
  string,
  { logo?: string; hero?: string; heroMobile?: string }
> = {
  ambev: {
    logo: AmbevLogo,
    hero: AmbevHero,
  },
  bees: {
    logo: BeesLogo,
    hero: BeesHero,
  },
  generic: {
    hero: GenericHero,
  },
  getinb: {
    logo: GetinLogo,
  },
  twoInOne: {
    hero: TwoInOneHero,
  },
  savee: {
    hero: SaveeHero,
    logo: SaveeLogo,
  },
  kibon: {
    hero: KibonHero,
    logo: KibonLogo,
    heroMobile: KibonHeroMobile,
  },
  gympass: {
    hero: GympassHero,
    logo: GympassLogo,
    heroMobile: GympassHeroMobile,
  },
}

export default Images
