import { Button, Grid, Icon, Link, Logo } from '@lemonenergy/lemonpie'
import { Link as RemixLink, NavLink as RemixNavLink } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { gtag } from '~/utils/gtagEvents'
import images from '~/utils/landing-page-images'

type MenuProps = {
  isMenuOpen?: boolean
  hasPartner?: boolean
  hasPartnerLogo?: boolean
}

export const NavButton = styled.div<MenuProps>(
  ({ isMenuOpen, theme: { colors, media, spacing } }) => {
    return css`
      display: flex;
      flex: 0 0 auto;
      margin-left: auto;

      > a {
        text-decoration: none;

        > ${Button} {
          max-height: 56px;
          > span:first-child {
            margin-left: 0;
          }

          > span:last-child {
            margin-left: ${spacing.sm};
          }
        }
      }

      ${media.only.xs} {
        background-color: ${colors.neutral.lightest};
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        padding: ${spacing.sm} ${spacing.sm} calc(${spacing.sm} + 2px);
        margin-bottom: -2px;

        > a {
          &,
          > ${Button} {
            width: 100%;
          }
        }

        ${!isMenuOpen
          ? css`
              display: none;
            `
          : ''}
      }
    `
  },
)

const NavLink = styled(Link)(
  ({ theme: { colors } }) => css`
    align-items: center;
    color: ${colors.neutral.darkest};
    display: flex;
    text-decoration: none;

    &.active {
      color: ${colors.primary.main};
      text-decoration: underline;
      text-underline-offset: 2px;
    }
  `,
)

const Menu = styled.button(
  ({ theme: { colors, media } }) => css`
    appearance: none;
    border: 0;
    background: transparent;
    color: ${colors.neutral.darkest};

    ${media.sm} {
      display: none;
    }
  `,
)

const Container = styled.header<MenuProps>(
  ({ isMenuOpen, theme: { colors, media } }) => {
    return css`
      background-color: ${colors.neutral.lightest};
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;

      ${isMenuOpen &&
      css`
        ${media.only.xs} {
          height: 100dvh;
        }
      `}
    `
  },
)

const NavLogo = styled(Link)(
  ({ theme: { colors } }) => css`
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.neutral.darkest};
  `,
)

const Nav = styled.nav<MenuProps>(
  ({ hasPartner, isMenuOpen, theme: { colors, media, spacing } }) => {
    const partnerStyles = hasPartner
      ? css`
          ${media.sm} {
            gap: ${spacing.md};
            justify-content: flex-start;

            > * {
              vertical-align: middle;
            }
          }
        `
      : ''

    return css`
      background-color: ${colors.neutral.lightest};
      color: ${colors.neutral.darkest};
      display: flex;
      justify-content: space-between;
      padding: ${spacing(2.5)} 0;
      width: 100%;
      position: relative;

      > img {
        align-self: center;
        max-height: 32px;
      }

      ul {
        background-color: ${colors.neutral.lightest};
        display: none;
        flex-direction: column;
        list-style-type: none;
        margin: 0;
        padding: 0;
        flex: 1 1 100%;

        ${media.sm} {
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
          gap: ${spacing.sm};
        }

        ${media.only.sm} {
          > li:last-child {
            display: none;
          }
        }

        ${media.md} {
          gap: ${spacing.md};
        }
      }

      ${media.only.xs} {
        ${isMenuOpen &&
        css`
          ul {
            position: absolute;
            display: block;
            top: 64px;
            left: 0;
            width: 100%;
            height: calc(100dvh - 64px);
            margin: 0;
            padding: 0;

            li {
              border-bottom: 1px solid ${colors.neutral.light};
              display: flex;
              height: ${spacing(14)};

              a {
                flex: 1;
              }
            }
          }
        `}
      }

      ${partnerStyles}
    `
  },
)

const Header: React.FC<{
  partner?: { name: string; logo: string }
  headerCTA?: string
}> = ({ partner, headerCTA }) => {
  const [menuOpened, setMenuOpened] = useState<boolean>(false)

  const handleClickOnMenu = () => setMenuOpened(!menuOpened)

  const handleResize = () => {
    setMenuOpened(false)
  }

  const handleClickOnLink = () => {
    if (typeof window === 'undefined') return
    if (window.requestAnimationFrame) {
      let start: number
      const loop = () => {
        window.requestAnimationFrame(dt => {
          if (!start) start = dt
          const elapsed = dt - start
          if (elapsed < 300) return loop()
          setMenuOpened(false)
        })
      }

      loop()
    } else {
      setTimeout(() => setMenuOpened(false), 300)
    }
  }

  const trackHeaderClick = (e: BaseSyntheticEvent) =>
    gtag(
      {
        event: 'click_interaction',
        source_id: 'header-button',
        interaction_type: 'button',
        label: 'Botão do header que direciona o usuário para o formulário',
      },
      e,
    )

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const defaultHeaderLink = partner ? '#cadastro' : '/cadastro'
  const pathCTA = headerCTA ?? defaultHeaderLink
  const prefetchPathCTA = !pathCTA.startsWith(`#`) ? 'render' : 'none' // We do not want to prefetch if the hyperlink is an anchor to its own section.
  const pathLogo = !partner ? '/' : '#cadastro'

  return (
    <Container isMenuOpen={menuOpened}>
      <Grid.Container>
        <Grid.Row>
          <Grid.Col>
            <Nav hasPartner={Boolean(partner)} isMenuOpen={menuOpened}>
              <NavLogo as={RemixLink} to={pathLogo}>
                <Logo size="lg" variant="normal" />
              </NavLogo>

              {partner?.logo && (
                <img
                  src={images[partner.logo]?.logo ?? ''}
                  alt={partner.name}
                />
              )}
              {!partner && (
                <ul>
                  <li>
                    <NavLink
                      as={RemixNavLink}
                      to="/"
                      onClick={(_: React.MouseEvent<HTMLAnchorElement>) => {
                        handleClickOnLink()
                      }}
                      prefetch="render"
                    >
                      Início
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={RemixNavLink}
                      to="/como-funciona"
                      onClick={(_: React.MouseEvent<HTMLAnchorElement>) => {
                        handleClickOnLink()
                      }}
                      prefetch="render"
                    >
                      Como funciona
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={RemixNavLink}
                      to="/sobre"
                      onClick={(_: React.MouseEvent<HTMLAnchorElement>) => {
                        handleClickOnLink()
                      }}
                      prefetch="render"
                    >
                      Sobre a Lemon
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      as={RemixNavLink}
                      to="/geradores"
                      onClick={(_: React.MouseEvent<HTMLAnchorElement>) => {
                        handleClickOnLink()
                      }}
                      prefetch="render"
                    >
                      Seja um gerador
                    </NavLink>
                  </li>
                </ul>
              )}

              <NavButton
                isMenuOpen={menuOpened}
                hasPartner={Boolean(partner)}
                hasPartnerLogo={Boolean(partner?.logo)}
              >
                <RemixLink
                  to={pathCTA}
                  onClick={trackHeaderClick}
                  prefetch={prefetchPathCTA}
                >
                  <Button inline icon="arrow-right">
                    Realizar Cadastro
                  </Button>
                </RemixLink>
              </NavButton>
              {!partner && (
                <Menu onClick={handleClickOnMenu}>
                  {menuOpened ? <Icon name="cross" /> : <Icon name="menu" />}
                </Menu>
              )}
            </Nav>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Container>
  )
}

export default Header
