import { Grid, Link, Logo, Text } from '@lemonenergy/lemonpie'
import { Link as RemixLink } from '@remix-run/react'
import type { BaseSyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

import Section from './Section'
import Social from './Social'

import { gtag } from '~/utils/gtagEvents'

const footerPaths = {
  HOME: '/',
  HOW_IT_WORKS: '/como-funciona',
  ABOUT: '/sobre',
  GENERATORS: '/geradores',
  SIGN_UP: '/cadastro',
  PRIVACY_POLICY: '/politica-de-privacidade',
  CONDUCT_CODE: 'https://lmn.page/codigo-etica-conduta',
  TERMS_OF_USE: '/termos-de-uso',
  REPORT: 'https://lmn.page/compliance_report',
  JOBS: 'https://lmn.page/vagas',
  NOTICE: '/editais',
} as const

const Container = styled(Section)(
  ({
    theme: { borderStyle, borderWidth, colors, fontWeight, media, spacing },
  }) => css`
    margin-top: auto;
    color: ${colors.neutral.lightest};

    ${Logo} {
      margin-bottom: ${spacing(6)};
    }

    ${Link} {
      color: inherit;
      font-weight: ${fontWeight.bold};
      margin-bottom: ${spacing(4.5)};
      text-decoration: none;
    }

    ${Text} a {
      color: inherit;
      margin-top: ${spacing.xs};
      text-decoration: none;
    }

    ${Grid.Container} {
      width: 100%;
    }

    hr {
      border: 0;
      border-color: ${colors.neutral.dark};
      border-style: ${borderStyle.solid};
      border-bottom-width: ${borderWidth.main};
      margin-top: 0;
      margin-bottom: ${spacing(4.5)};
      width: 100%;
    }

    ${Social} {
      margin: ${spacing.md} 0;
    }

    ${media.sm} {
      ${Social} {
        margin-top: ${spacing.md};
        margin-bottom: ${spacing(6)};
      }
    }
    ${media.only.sm} {
      ${Text}, ${Link} {
        font-size: 0.8rem;
      }
    }
  `,
)

const trackFooterLink = (label: string) => (e: BaseSyntheticEvent) => {
  gtag(
    {
      event: 'click_interaction',
      interaction_type: 'link',
      source_id: `link-${label}`,
      label: `Clique no link ${label}`,
    },
    e,
  )
}

const Footer: React.FC<{ footerCTA?: string }> = ({ footerCTA }) => {
  const pathCTA = footerCTA ?? footerPaths.SIGN_UP

  return (
    <Container as="footer" variant="neutral.darkest">
      <Grid.Container>
        <Grid.Row justify={{ sm: 'flex-start' }}>
          <Grid.Col xs={12} sm={3}>
            <Logo variant="normal" size="lg" />
          </Grid.Col>

          <Grid.Col xs={12} sm={3}>
            <Link
              as={RemixLink}
              to={footerPaths.HOME}
              onClick={trackFooterLink('home')}
            >
              Início
            </Link>
            <Link
              as={RemixLink}
              to={footerPaths.HOW_IT_WORKS}
              onClick={trackFooterLink('como_funciona')}
            >
              Como funciona
            </Link>
            <Link
              as={RemixLink}
              to={footerPaths.ABOUT}
              onClick={trackFooterLink('sobre')}
            >
              Sobre a Lemon
            </Link>
            <Link
              as={RemixLink}
              to={footerPaths.GENERATORS}
              onClick={trackFooterLink('geradores')}
            >
              Seja um gerador
            </Link>
            <Link
              as={RemixLink}
              to={pathCTA}
              onClick={trackFooterLink('cadastro')}
            >
              Realizar Cadastro
            </Link>
            <Grid.Visible xs>
              <hr />
            </Grid.Visible>
          </Grid.Col>
          <Grid.Col xs={12} sm={3}>
            <Link
              as={RemixLink}
              to={footerPaths.PRIVACY_POLICY}
              onClick={trackFooterLink('politica_de_privacidade')}
            >
              Política de Privacidade
            </Link>
            <Link
              href={footerPaths.CONDUCT_CODE}
              target="_blank"
              onClick={trackFooterLink('codigo_de_conduta')}
            >
              Código de ética e conduta
            </Link>
            <Link
              href={footerPaths.REPORT}
              target="_blank"
              onClick={trackFooterLink('fazer_um_relato')}
            >
              Fazer um relato
            </Link>
            <Link
              as={RemixLink}
              to={footerPaths.TERMS_OF_USE}
              onClick={trackFooterLink('termos_de_uso')}
            >
              Termos de Uso
            </Link>
            <Link
              href={footerPaths.JOBS}
              target="_blank"
              onClick={trackFooterLink('carreiras')}
            >
              Carreiras
            </Link>
            <Link
              as={RemixLink}
              to={footerPaths.NOTICE}
              onClick={trackFooterLink('editais')}
            >
              Editais
            </Link>
          </Grid.Col>
          <Grid.Col xs={12} sm={3}>
            <Grid.Visible xs>
              <hr />
            </Grid.Visible>
            <Text bold>Contato</Text>
            <Text>
              <a href="mailto:contato@energialemon.com.br">
                contato@energialemon.com.br
              </a>
            </Text>
            <Social source="footer" />
            <Text>© {new Date().getFullYear()} Lemon Energia</Text>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Container>
  )
}

export default Footer
